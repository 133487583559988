import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

/**
 * Servicio que se encarga de gestionar las politicas
 */
export class PoliticasService
{
    constructor(private http: HttpClient) {}

    // Método que carga el HTML desde una URL
    loadHtml(url: string): Observable<string> {
        return this.http.get(url, { responseType: 'text' });
    }

    // Método que carga el CSS desde una URL
    loadCss(url: string): void {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      document.head.appendChild(link);
    }

    // Método que carga el JS desde una URL
    loadJs(url: string): void {
      const script = document.createElement('script');
      script.src = url;
      script.type = 'text/javascript';
      script.defer = true;
      document.body.appendChild(script);
    }


}