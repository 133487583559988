<div class="c-body">
  <div class="container text-center">

    <!-- Imagen Destinux -->
    <app-imagen-destinux></app-imagen-destinux>

    <!-- Formulario login -->
    <div class="row justify-content-center mt-5 mt-md-3 mt-lg-5">
      <div class="col-lg-4 col-md-6 col-sm-10">

        <form [formGroup]="form">

          <!-- Imput Email -->
          <app-input-group
            id="correo"
            type="email"
            nombre="correo"
            clase="form-control input-icono"
            claseSpan="input-group-text label-icono"
            nombreIcono="email"
            icono="g--color-blue-icon-7"
            [control]="form.get('correo')"
            placeholder="{{'precliente.login.label.porfavorcorreo' | translate }}"
            [isRequired]="true"
          ></app-input-group>

           <!-- Imput Codigo -->
          <div *ngIf="usuarioIdentificado">
            <app-input-group
              id="codigo"
              type="text"
              nombre="codigo"
              clase="form-control input-icono mt-2"
              claseSpan="input-group-text label-icono mt-2"
              nombreIcono="lock_open"
              icono="g--color-blue-icon-7"
              [control]="form.get('codigo')"
              placeholder="{{'precliente.login.label.codigo' | translate }}"
              [isRequired]="true"
            ></app-input-group>
          </div>

          <div class="mt-3 l-flex l-flex--space-between">

            <!-- Seleccion de idioma -->
            <app-banderas tipo="dropdown"></app-banderas>

            <!-- Boton enviar formulario -->
            <div *ngIf="!usuarioIdentificado">
              <app-boton
                clase="c-button c-button--transparent c-text g--padding-16"
                [nombre]="'precliente.login.boton.enviar'"
                (click)="enviarFormulario()"
              ></app-boton>
            </div>
            
            <!-- Boton para entrar en la ficha-->
            <div *ngIf="usuarioIdentificado">
              <app-boton
                clase="c-button c-button--transparent c-text g--padding-16"
                [nombre]="'precliente.login.boton.entrar'"
                (click)="verificarCodigo()"
              ></app-boton>
            </div>
          </div>

        </form>

      </div>
    
      <div class="justify-content-center">
        <div class="col-12  col-md-12 d-flex flex-column flex-md-row align-items-center justify-content-center py-5 Condiciones-politica link-container">
          <a class="mb-2 mb-md-0 enlace" href="/politicas-privacidad" target="_blank">{{'login.politica-privacidad' | translate }}</a>
          <a class="mb-2 mb-md-0 enlace" href="/condiciones-generales" target="_blank">{{'login.condiciones-generales' | translate }}</a> 
          <a class="mb-2 mb-md-0 enlace" href="/politicas-seguridad" target="_blank">{{'login.politica-seguridad' | translate }}</a> 
        </div>
      </div>


      <!-- Imagen footer -->
      <app-imagen-footer-login [anyadirClasePy]="true"></app-imagen-footer-login>

    </div>
  </div>
</div>

    
   